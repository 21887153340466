<template>
  <v-card
    title="card-title"
    elevation="1"
    class="none-overflow"
    v-ripple
    @click="showDialog"
    ><v-card-title class="card-title">自定义</v-card-title>

    <v-card-subtitle>管理常用功能</v-card-subtitle>
    <div class="content">
      <div class="icon">
        <svg-icon
          :url="require('@/assets/svgimgs/undraw_switches_1js3.svg')"
          width="5rem"
          height="4rem"
        ></svg-icon>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        rounded
        small
        plain
        class="right-button btn-disabled"
        @click="zzzMid"
        >设置首页
        <v-icon right>mdi-cog</v-icon>
      </v-btn>
    </v-card-actions>

    <v-row justify="center" v-show="dialog">
      <v-dialog v-model="dialog" scrollable max-width="25rem">
        <v-card class="rounded-lg none-overflow">
          <v-card-title style="font-weight: normal; background-color: #ffd7af"
            >首页功能开关
          </v-card-title>
          <!--          <v-divider></v-divider>-->
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="12">
                <p
                  style="
                    width: 15rem;
                    margin: 0.3rem auto;
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: #a9a9a9;
                  "
                >
                  部分卡片在大屏设备仍会显示！
                </p>
                <div class="switch">
                  学习情况分析
                  <v-switch
                    v-model="tempCards.statistics"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  课程表
                  <v-switch
                    v-model="tempCards.classTable"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  单词表
                  <v-switch
                    v-model="tempCards.wordtable"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  每日一言
                  <v-switch
                    v-model="tempCards.motto"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  白噪音
                  <v-switch
                    v-model="tempCards.emptyNoice"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  里程碑
                  <v-switch
                    v-model="tempCards.mileStone"
                    color="#ffd7af"
                  ></v-switch>
                </div>
                <div class="switch">
                  专注模式
                  <v-switch
                    v-model="tempCards.focusMode"
                    color="#ffd7af"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveConfig"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";
import SvgIcon from "@/components/beauty/svgIcon";
import { deepClone, isObjEqual } from "@/utils/common";
import $api from "@/api/api";
import { mapGetters } from "vuex";

export default {
  name: "settingHome",
  components: { SvgIcon },
  mixins: [buttonVibrate],
  computed: {
    ...mapGetters({
      userPreferrence: "userPreferrence",
    }),
  },
  data: () => ({
    btnLoading: false,
    dialog: false,
    tempCards: {
      motto: true,
      statistics: true,
      wordtable: true,
      classTable: true,
      emptyNoice: true,
      focusMode: true,
      mileStone: true,
    },
  }),
  methods: {
    showDialog() {
      this.tempCards = deepClone(this.userPreferrence.cards);
      this.dialog = true;
    },
    saveConfig() {
      this.btnLoading = true;
      if (isObjEqual(this.tempCards, this.userPreferrence.cards)) {
        this.btnLoading = false;
        this.dialog = false;
        return;
      }
      let postData = deepClone(this.tempCards);
      $api
        .setUserCardConfig(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.$toast.success(res.msg);
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          this.$store.commit("setUserPreferrenceHomeCards", postData);
          this.dialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 15rem;
  height: 3rem;
  font-size: 0.9rem;
  letter-spacing: 0.2rem;
}
.content {
  width: 100%;
  height: 4.5rem;
  //margin-top: -0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #ffad68;
  .icon {
    width: 4.5rem;
  }
}
.card-title {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  color: #404040;
  margin-top: -0.5rem;
}
.right-button {
  position: absolute;
  right: 0.6rem;
  bottom: 0.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  color: #dc7841;
  font-size: 0.9rem;
  font-weight: 700;
  width: 6.5rem;
  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
